import { render, staticRenderFns } from "./CallCollector.vue?vue&type=template&id=e71832b8&scoped=true&lang=pug&"
import script from "./CallCollector.vue?vue&type=script&lang=js&"
export * from "./CallCollector.vue?vue&type=script&lang=js&"
import style0 from "./CallCollector.vue?vue&type=style&index=0&id=e71832b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e71832b8",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardActions,QInput,QIcon,QPopupProxy,QDate,QBtn,QTime,QSeparator,QCardSection,QTable});qInstall(component, 'directives', {ClosePopup});

import Vue from 'vue'

import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Meta from 'quasar/src/plugins/Meta.js';import Dialog from 'quasar/src/plugins/Dialog.js';

Vue.use(Quasar, {
  plugins: {
    Notify,
    Meta,
    Dialog
  },
  config: {
  }
})
import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=62151cd4&scoped=true&lang=pug&"
import script from "./ResetPassword.vue?vue&type=script&lang=js&"
export * from "./ResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./ResetPassword.vue?vue&type=style&index=0&id=62151cd4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62151cd4",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QInput,QBtn});

<template lang="pug">
  q-card(v-if="data.active")
    q-card-section(class="row items-center")
      span(class="q-ml-sm text-h6") {{ title }}
    q-card-section(class="row items-center")
      template(v-for="g in gateways")
        div {{ g.name }}
        div
          span(v-for="l in g.lines")
            q-checkbox(v-model="user_lines_data[g.name]" :val="l" :label="l" color="teal")
      q-btn(
        flat
        :loading="data.submitting"
        label="Изменить"
        color="secondary"
        @click="changeAvailableLines()"
      )
        template(v-slot:loading)
          q-spinner-facebook
      q-btn(
        flat
        label="Отмена"
        color="primary"
        v-on:click="$emit('close', model)"
      )
</template>

<script>
export default {
  name: "PopupShareLines",
  props: ['title', 'submit', 'model', 'data', 'gateways', 'user_lines_data', 'edit_user_id'],
  data() {
    return {}
  },
  methods: {
    changeAvailableLines() {
      let data = []
      for (let i in this.user_lines_data) {
        data.push({'name': i, 'lines': this.user_lines_data[i]})
      }
      this.submit(`/employee/${this.edit_user_id}/share-gateway-lines/`, {'data': {'data': data, 'type': 'custom'}}, 'share_lines', 'employee')
    }
  }
}
</script>

import { render, staticRenderFns } from "./App.vue?vue&type=template&id=9188c0e6&lang=pug&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=9188c0e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QTabs,QTab,QTabPanels,QTabPanel,QCardSection,QInput,QColor,QBtn,QLayout,QHeader,QToolbar,QMenu,QList,QItem,QItemSection,QIcon,QItemLabel,QToolbarTitle,QDrawer,QPageContainer,QTooltip});qInstall(component, 'directives', {Ripple});
